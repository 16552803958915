@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  width: 100%;
  @extend %flex-align;

  input[type='checkbox'] {
    min-width: 20px;
    height: 20px;
    cursor: pointer;
    appearance: none;
    border-radius: 4px;
    transition: 0.2s ease;
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid $secondary-1500;
    @extend %flex-center;

    &:hover {
      border-color: $secondary-1800;
    }

    &:checked {
      border-color: $primary-10;
    }

    &::after {
      content: '';
      min-width: 10px;
      min-height: 10px;
      display: none;
      border-radius: 2px;
      background-color: $primary-10;
    }

    &:checked::after {
      display: block;
      animation: checkboxAnimation 0.5s ease-in-out;
    }
  }

  &__label {
    color: $secondary-1500;
    cursor: pointer;
    gap: 8px;
    transition: all 0.5s;
    @extend %body1-lt;

    &:hover {
      color: $primary-1900;
    }

    &__checked {
      color: $primary-1900;
    }
  }
}

.disabled {
  opacity: 0.5;
  color: $secondary-1500;

  label,
  input {
    cursor: not-allowed !important;
  }
}

.left {
  @extend %flex-align;
}

.right {
  @extend %flex-align;
}

.right_start {
  @extend %flex-align-start;
}

.checkbox {
  margin-top: 4px;
}

.top {
  @extend %flex-column-center;
}

.bottom {
  @extend %flex-center;
  flex-direction: column-reverse;
}

@keyframes checkboxAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
