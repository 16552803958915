@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  @extend %flex-column;
  @include scrollbar(6px);
  height: calc(100vh - 326px);

  &__section {
    @extend %flex-column;

    &__title {
      @extend %micro-r;
      @extend %flex-align;
      color: $primary-200;
      padding: 20px 0 5px 20px;
    }

    &__menu {
      @extend %flex-column;

      &__item {
        transition: all 1s;
        display: flex;
        gap: 10px;
        height: 40px;
        padding: 10px 18px;
        overflow: hidden;

        svg {
          width: 20px;
          height: 20px;
        }

        &__text {
          transition: all 1s;
          color: $secondary-1600;
          @extend %body1-r;
        }

        &:hover {
          transition: all 0.5s;
          background-color: $background-80;

          @include svgFill(white);

          @media (max-width: 576px) {
            background-color: transparent;
          }

          span {
            color: white;
          }
        }

        @include svgFill($secondary-1600);
      }

      &__active__item {
        background-color: $background-70;
        @media (max-width: 576px) {
          background-color: transparent;
        }

        &__text {
          color: $primary-10;
        }

        &:hover {
          background-color: $background-70;

          @media (max-width: 576px) {
            background-color: transparent;
          }
        }

        @include svgFill($primary-10 !important);
      }
    }
  }
}

.wrapper__closed__section {
  &__title {
    width: 100%;
    height: 43px;
    background-color: $background-80;
  }

  &__menu__item {
    padding: 10px 30px;

    &__text {
      opacity: 0;
    }
  }
}
