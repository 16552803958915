#toolbar {
  border: none;
  background: #142837;
  border-bottom: 1px solid #273c53;

  //   .ql-bold
  .ql-formats button,
  span {
    svg path,
    line,
    polyline,
    rect {
      stroke: #fff;
    }

    .ql-strike svg {
      path {
        fill: #fff;
      }
    }

    .ql-header {
      color: #fff;

      svg polygon {
        stroke: #fff;
      }
    }
  }
}

.quill {
  background: #142837;
  min-height: 300px;

  .ql-container {
    border: none !important;
    color: #fff;
  }
}
