@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  width: 100%;

  @media (max-width: 576px) {
    &__email__notify {
      padding: 16px 0;

      &__icon {
        svg {
          width: 36px;
          height: 36px;
        }
      }

      &__text {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-left: 12px;
      }
    }

    &__link {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &__label {
    @extend %body2-r;
    color: $secondary-1600;
  }

  &__email__notify {
    padding: 32px 0;
    @extend %flex-align;

    &__icon {
      svg {
        width: 72px;
        height: 72px;
      }
    }

    &__text {
      @extend %body2-r;
      margin-left: 24px;
      color: $secondary-1700;
    }

    &__resend {
      cursor: pointer;
      margin-left: 8px;
      color: $primary-1900;
    }
  }

  &__link {
    cursor: pointer;
    color: $primary-10;
    text-align: center;
    @extend %button-text;
  }
}
