@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background-color: $background-200;

  &__right {
    width: 100%;
    opacity: 0;
    padding: 32px;
    overflow: hidden;
    position: relative;
    animation: fadeInRight 1s 2s 1 forwards;
    @extend %flex-center;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
