@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.button {
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.2s;
  max-height: 48px;
  @extend %button-text;
  @extend %flex-center;

  &:disabled {
    cursor: not-allowed;
  }
}

.loading {
  border: 1px solid $success;
  @extend %body1-r;
  gap: 15px;
}

.primary__loading {
  color: $white;
  background-color: $success;

  div > div > div:nth-child(1) {
    border-color: $success-50;
  }
}

.secondary__loading {
  color: $success;
  background-color: unset;
  border: 1px solid $success;

  div > div > div:nth-child(1) {
    border-color: $success;
  }
}

.primary {
  color: $white;
  background-color: $primary-200;
  border: 1px solid $primary-200;

  &__filled {
    @include svgFill($white);

    &:hover {
      background-color: $primary-300;
      border: 1px solid $primary-300;
    }

    &:disabled {
      @include svgFill($secondary-1300);
    }
  }

  &:disabled {
    color: $secondary-1300;
    background-color: unset;
    border: 1px solid $secondary-1300;
  }

  &:focus {
    border: 1px solid $primary-1500;
  }
}

.secondary {
  border: 1px solid $button-secondary-border;
  background-color: unset;
  color: $button-secondary-text;

  &__filled {
    @include svgFill($button-secondary-text);

    &:hover {
      @include svgFill($primary-10) {
        transition: all 0.2s;
      }
    }
  }

  &:hover {
    color: $button-secondary-text-hover;
    background-color: $button-secondary-bg-hover;
  }

  &:disabled {
    color: $button-secondary-text-disabled;
    border-color: $button-secondary-border-disabled;
    @include svgFill($button-secondary-text-disabled);
  }

  &:focus {
    border-color: $primary-1600;
  }
}

.tertiary {
  color: $button-tertiary-text;
  background-color: $button-tertiary-bg;
  border: 1px solid transparent;

  &__filled {
    @include svgFill($button-tertiary-text);

    &:hover {
      @include svgFill($button-tertiary-text-hover-focus);
    }

    &:disabled {
      @include svgFill($button-tertiary-text-disabled);
    }
  }

  &:hover {
    color: $button-tertiary-text-hover-focus;
    background-color: $button-tertiary-bg-hover-focus;
  }

  &:disabled {
    color: $button-tertiary-text-disabled;
    background-color: $button-tertiary-bg-disabled;
  }

  &:focus {
    color: $button-tertiary-text-hover-focus;
    background-color: $button-tertiary-bg-hover-focus;
    border: 1px solid $button-tertiary-border-focus;
  }
}

.large__only__icon {
  padding: 11px !important;
}

.medium__only__icon {
  padding: 9px !important;
}

.small__only__icon {
  padding: 6px !important;
}

.large {
  padding: 12px 32px;

  &__icon {
    width: 24px;
    height: 24px;
    @extend %flex-center;

    & > div > div {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.medium {
  padding: 9px 50px;
  @extend %body2-sb;

  &__icon {
    width: 20px;
    height: 20px;
    @extend %flex-center;

    & > div > div {
      width: 18px !important;
      height: 18px !important;
    }
  }
}

.small {
  padding: 5px 18px;
  @extend %body2-sb;

  &__icon {
    width: 20px;
    height: 20px;
    @extend %flex-center;

    & > div > div {
      width: 14px !important;
      height: 14px !important;
    }
  }
}
