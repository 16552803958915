@import 'styles/colors.scss';
@import 'styles/mixins.scss';
@import 'styles/typography.scss';
@import 'styles/vars.scss';

.wrapper {
  min-width: 96px;
  height: 48px;
  border: 1px solid $primary-600;
  background-color: $background-90;
  border-radius: 7px;
  position: relative;
  cursor: pointer;
  @extend %flex-justify-between;

  &__item {
    width: 50%;
    @extend %flex-center;

    &:hover {
      @include svgFill($primary-10);
    }

    svg {
      z-index: 1;

      path {
        transition: 0.5s all ease-in-out;
      }
    }
  }

  &__switcher {
    height: 100%;
    background-color: $primary-900;
    position: absolute;
    border-radius: 6px;
    z-index: 0;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
  }

  &__switcher_active {
    transform: translate(100%);
  }

  &__light_active {
    @include svgFill($primary-10);
  }
}
