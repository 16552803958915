@import 'styles/colors';
@import 'styles/typography';
@import 'styles/vars.scss';

.wrapper {
  width: 72px;
  position: relative;
  @extend %body1-r;
  color: $primary-1900;

  &__text {
    @extend %flex-center;
    gap: 6px;
    padding: 8px 0;
    cursor: pointer;
  }

  &__dropdown {
    width: 72px;
    position: absolute;
    background: $primary-900;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    max-height: 0;
    height: 100vh;
    transition: 0.3s all ease;
    @extend %flex-column-center;
    overflow: hidden;

    &__item {
      padding: 0;
      max-height: 0;
      opacity: 0;
      color: $secondary-1500;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: $background-90;
        color: $primary-1900;
      }
    }
  }

  &__dropped {
    max-height: 112px;
    height: 100vh;

    p {
      opacity: 1;
      padding: 12px 19px;
      max-height: 48px;
      height: auto;
    }
  }

  &__active__lang {
    color: $primary-10;

    &:hover {
      color: $primary-10;
      background-color: unset;
    }
  }
}
