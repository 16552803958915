@import 'styles/typography';

.wrapper {
  //   border: 1px dashed #3f5166;
  background: #142837;
  border-radius: 16px;
  height: fit-content;
  max-width: 440px;
  max-height: 330px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 24px;
  gap: 4px;
  text-align: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='white' stroke-width='4' stroke-dasharray='33' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  &__active {
    overflow: hidden;
    background: transparent;
    background-image: none;

    p,
    svg {
      display: none;
    }
  }

  image {
    width: 100%;
    height: 100%;
    object-fit: covers;
  }

  input {
    display: none;
  }

  p {
    color: #8793a0;
    @extend %micro-lt;
  }
}
