.wrapper {
  width: fit-content;
  position: absolute;
  top: 4px;
  right: -16px;

  &__button {
    z-index: 2;
    position: relative;
  }

  svg {
    transition: all 0.5s;
  }

  &__active {
    svg {
      transform: rotate(180deg);
    }
  }
}
