@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 16px;
  z-index: 4;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  background: $background-90;
  @extend %flex-justify-between;

  &__left {
    gap: 16px;
    @extend %flex-align;

    &__btn {
      @include svgFill($primary-100);
    }
  }

  &__center {
    gap: 8px;
    @extend %flex-align;

    &__text {
      @extend %heading-1;
      letter-spacing: 2px;
      color: $primary-1800;
    }
  }

  &__right {
    background: #0f2640;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 24px;
    color: #9fa8b3;
    font-size: 14px;
    height: 100%;

    div {
      width: 32px;
      height: 32px;
      border: 1px solid #0077c8;
      border-radius: 50%;
      padding: 4px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
