@import 'styles/colors';
@import 'styles/typography';
@import 'styles/mixins';

.textarea {
  position: relative;
  width: 320px;
  height: 88px;

  .label__text {
    @extend %body2-r;
    color: $primary-1900;
  }

  &__label {
    font-size: 16px;
  }

  &:hover {
    textarea {
      border-color: $secondary-1400;
    }
  }

  label {
    display: block;
    width: 100%;
    position: relative;

    textarea {
      width: 100%;
      height: 88px;
      @extend %body2-lt;
      resize: none;
      transition: 0.3s all;
      background: $background-80;
      outline: none;
      border: 1px solid transparent;
      padding: 10px;
      color: $primary-1900;
      border-radius: 5px;
      list-style-type: circle;
      @include scrollbar(4px);

      &::placeholder {
        color: $secondary-1400;
        letter-spacing: 1px;
        text-transform: capitalize;
      }

      &:disabled {
        opacity: 0.5;
      }

      &:focus {
        background: $background-90;
        border-color: $primary-300;
      }
    }
  }
}

.textarea_large {
  width: 400px;
  height: 120px;

  label {
    textarea {
      height: 120px;
      @extend %body1-lt;
    }
  }
}
