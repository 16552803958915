@import 'src/styles/vars';
@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/typography';

.wrapper {
  gap: 4px;
  width: max-content;
  padding: 6px;
  border-radius: 24px;
  cursor: pointer;
  transition: 0.5s all;
  border: 1px solid transparent;
  @extend %flex-center;
  @include svgFill($secondary-1500, 20px);

  &:hover {
    border-color: $info-50;
    background-color: #0f284f;
    @include svgFill($info-50);

    .tooltip__text {
      color: $info-50;
    }
  }

  &__text {
    line-height: 0;
    color: $secondary-1500;
    @extend %micro-sb;
  }

  &__title {
    border-radius: 8px;
    padding: 8px 12px;
    color: $info-25;
    background-color: $secondary-30;
    @extend %micro-r;
  }

  &__error {
    @include svgFill($error);

    &__text {
      color: $error;
    }

    &:hover {
      border-color: $error;
      background-color: $error-25;
      @include svgFill($error);

      .wrapper__error__text {
        color: $error;
      }
    }

    &__title {
      background-color: $error-25;
    }
  }
}

.dark__text {
  color: $info-25;
  @extend %micro-r;
}

.light__text {
  color: $info-750;
}

.error__text {
  color: $error;
}
