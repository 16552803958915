@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  width: 100%;
  padding: 16px;
  background-color: $background-90;
  @extend %flex-justify-between-center;

  &__left {
    gap: 8px;
    @extend %flex-align;

    &__btn {
      width: unset;
    }

    &__text {
      @extend %body1-lt;
      color: $secondary-1600;
    }
  }

  &__main {
    gap: 16px;
    @extend %flex-align;

    &__id {
      width: 32px;
      height: 32px;
      @extend %body2-sb;
      border-radius: 8px;
      padding: 12.5px 6px;
      color: $primary-1500;
      @extend %flex-center;
      background-color: $secondary-30;
    }

    &__title {
      color: $primary-1900;
      @extend %secondary-heading;
    }
  }

  &__empty {
    width: 85px;
  }
}
