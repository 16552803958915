@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  border-radius: 8px;
  border: 1px solid $secondary-1100;
  background-color: $background-80;

  &__header {
    padding: 12px 16px;
    background-color: $background-70;
    @extend %flex-center;

    &__text {
      text-align: center;
      color: $primary-1900;
      @extend %subheading-b;
    }
  }

  &__bottom {
    gap: 24px;
    padding: 16px 24px;
    @extend %flex-wrap;

    &__item {
      width: max-content;

      &__text {
        color: $secondary-1200;
        @extend %body1-lt;

        &__selected {
          color: $primary-1900;
        }
      }
    }
  }
}
