@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  z-index: 4;
  width: 300px;
  max-height: 788px;
  transition: all 1s;
  border-radius: 16px;
  padding: 20px 0 25px 0;
  @extend %flex-column-between;
  background-color: $background-90;
}

.wrapper__closed {
  width: 80px;
  padding: 20px 0;
}

.is_open {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: fixed;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.7);
}
