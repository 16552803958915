@import 'styles/vars';

.wrapper {
  padding: 0 16px;

  &__inner {
    @extend %flex-column;
    gap: 15px;

    &__button {
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
