@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';

.wrapper {
  gap: 24px;
  width: 100%;
  height: 100vh;
  padding: 28px 32px;
  background-color: $background-170;
  display: flex;
  flex-direction: column;

  &__content {
    width: 100%;
    position: relative;
    height: -webkit-fill-available;
    display: flex;
    gap: 24px;
  }
}
