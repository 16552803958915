@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 28px;
  z-index: 997;
  @extend %flex-center;

  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 998;
    background: $background;
  }

  &__content {
    width: 100%;
    z-index: 999;
    border-radius: 8px;
    padding: 32px 17px 32px 32px;
    background-color: $background-90;

    &__container {
      overflow-y: auto;
      max-height: 70vh;
      padding-right: 15px;
      @include scrollbar(4px);

      @media (max-width: $tablet) {
        padding-right: 10px;
      }
    }

    @media (max-width: $tablet) {
      padding: 16px 5px 16px 16px;

      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }

      &__title_container {
        margin-bottom: 20px;
      }
    }

    &__icon {
      @include svgFill($secondary-1400, 20px);
    }

    &__title {
      @extend %heading-4;
      color: $primary-1900;
      width: 88%;

      &__centered {
        width: 100%;
        text-align: center;
      }
    }

    &__title_container {
      margin-bottom: 32px;
      @extend %flex-justify-between-center;
    }
  }
}
