@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;
  @extend %flex-justify-between-center;

  &__left {
    gap: 16px;
    @extend %flex-align;
    @include svgSize(48px, 48px);

    &__text {
      color: $primary-1900;
      @extend %heading-2;
    }
  }

  &__right {
    gap: 16px;
    @extend %flex-align;

    &__card {
      gap: 8px;
      padding-right: 16px;
      border-right: 1px solid $secondary-1100;
      @extend %flex-align;

      &__text {
        color: $secondary-1600;
        @extend %subheading-r;
      }

      &__price {
        color: $primary-1900;
        @extend %subheading-b;
      }
    }

    &__btns {
      gap: 16px;
      @extend %flex-align;
    }
  }
}
