@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.checkbox {
  @extend %flex-align;
  min-height: 45px;

  &__conditions {
    @extend %flex-wrap;
    @extend %body1-lt;
    color: $secondary-1500;

    &__links {
      width: fit-content;
      color: $primary-10;
      transition: all 0.6s;
      padding: 0 2px;
      @extend %flex-align;
      @include svgSize(20px);

      &:hover {
        color: $primary-200;
        text-decoration: underline;
      }

      &:focus {
        background-color: $primary-800;
        text-decoration: underline;
      }
    }
  }

  &__label {
    align-items: center;

    &_checkbox {
      margin-top: 5px;
    }
  }
}
