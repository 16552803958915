@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  width: fit-content;
  color: $primary-10;
  transition: all 0.6s;
  border-radius: 4px;
  gap: 4px;
  flex-wrap: nowrap;
  @extend %flex-align;
  @include svgSize(20px);

  &__filled {
    @include svgFill($primary-10) {
      transition: all 0.6s;
    }

    &:hover {
      @include svgFill($primary-200);
    }
  }

  &:hover {
    color: $primary-200;
    text-decoration: underline;
  }

  &:focus {
    background-color: $primary-800;
    text-decoration: underline;
  }
}

.disabled {
  cursor: no-drop;
  color: $secondary-1300;
  @include svgFill($secondary-1300);

  &:hover {
    color: $secondary-1300;
    @include svgFill($secondary-1300);
  }
}

.large {
  @extend %button-text;
}

.medium {
  @extend %body2-sb;
}

.small {
  @extend %micro-sb;
}
