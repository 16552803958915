@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  position: relative;

  &__select {
    top: 42px;
    z-index: 2;
    width: 100%;
    overflow-y: auto;
    max-height: 208px;
    position: absolute;
    padding: 8px 0;
    border-radius: 8px;
    background-color: $primary-900;
    border: 1px solid $secondary-100;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    @include scrollbar(4px);

    &__large {
      top: 54px;
    }

    &__active {
      color: $primary-10 !important;
    }

    &__de_active {
      cursor: default;
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  transition: all 0.6s;
  border: 1px solid transparent;
  background: $background-80;
  gap: 8px;
  @extend %flex-justify-between-center;

  &:hover {
    border-color: $secondary-1400;
  }

  &_open {
    border-color: $primary-10 !important;
  }

  &_large {
    padding: 11px 15px;
  }

  &_small {
    padding: 5px 11px;
  }

  &__text {
    color: $primary-1900;
    text-wrap: nowrap;

    &__placeholder {
      color: $secondary-1400;
      text-wrap: nowrap;
    }

    &_large {
      @extend %body1-lt;
    }

    &_small {
      @extend %body2-lt;
    }
  }

  &__arrow {
    transform: rotate(90deg);
    transition: transform 300ms;
    width: 20px;
    height: 20px;
    @include minMaxSize(20px, 20px);

    & > path {
      fill: $secondary-1400;
    }

    &_transform {
      transform: rotate(270deg);
      transition: transform 300ms;

      & > path {
        fill: $primary-10;
      }
    }
  }
}

.select_item {
  border: none;
  outline: none;
  border-radius: 0;
  text-align: left;
  color: $secondary-1500;
  justify-content: flex-start;
  padding: 12px 16px;
  @extend %body1-r;
  @extend %flex-align-end;
  @include svgFill($warning-75 !important, 23px !important);

  svg {
    cursor: default;
  }

  &:hover {
    border: none;
    color: $primary-1900;
    background: $primary-800;
    transition: 0.8s all !important;
  }

  &_de_active {
    color: $secondary-1100 !important;
    background-color: transparent !important;
  }
}
