%heading-1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}

%heading-2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}

%heading-3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

%heading-4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

%subheading-b {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

%subheading-sb {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

%subheading-m {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

%subheading-r {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

%secondary-heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

%button-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

%highlight-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

%body1-r {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

%body1-lt {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

%body2-sb {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

%body2-r {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

%body2-lt {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

%micro-sb {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

%micro-r {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

%micro-lt {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}
