.coin {
  gap: 8px;
  display: flex;
  align-items: center;

  &__icon {
    width: 20px;
    height: 20px;
  }
}

.inner__button {
  background: #ed5151 !important;
}

.modal {
  max-width: 400px;

  &__description {
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
  }

  button {
    height: 40px;

    &:last-child {
      margin-top: 20px;
    }
  }
}
