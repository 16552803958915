@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;
  max-width: 1756px;
  display: flex;
  min-height: 80px;
  border-top: 1px solid $secondary-1100;
  background-color: $background-90;
  border-radius: 0 0 16px 16px;

  &__enter {
    padding: 0 32px;
    white-space: nowrap;
    background-color: $background-70;
    @extend %subheading-b;
    @extend %flex-center;
    border-bottom-left-radius: 16px;
    color: $primary-1600;
  }

  &__pay_method_title {
    @extend %flex-align;
    @extend %secondary-heading;
    white-space: nowrap;
    gap: 8px;
    color: $primary-1900;
    @include svgFill($primary-1900, 24px);
  }

  &__input {
    max-width: 104px;
  }

  &__crypto {
    @extend %flex-justify-between-center;
    width: 100%;
    max-width: 1289px;
    padding: 0 20px 0 32px;
    border-right: 1px solid $secondary-1300;

    &__left {
      display: flex;
      gap: 16px;
    }

    &__checkbox {
      input[type='checkbox'] {
        border: 2px solid $secondary-1500;
      }

      &__active {
        input[type='checkbox'] {
          border: 2px solid $primary-10;
        }
      }

      &_label {
        color: $secondary-1500 !important;
        transition: all 0.5s;

        &__active {
          color: $primary-10 !important;
        }
      }
    }
  }

  &__credit {
    @extend %flex-align;
    padding: 0 32px 0 20px;
    min-width: 302px;
    gap: 24px;
  }
}
