@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

$backgroundBig: '../../../../assets/images/authContainer/backgroundBig.png';
$backgroundMid: '../../../../assets/images/authContainer/backgroundMid.png';
$backgroundSmall: '../../../../assets/images/authContainer/backgroundSmall.png';
$backgroundExtraSmall: '../../../../assets/images/authContainer/backgroundExtraSmall.png';

.wrapper {
  width: 100%;
  max-width: 950px;
  gap: 24px;
  padding-bottom: 52px;
  @extend %flex-column-between;

  &__column {
    gap: 16px;
    @extend %flex-column;
  }

  &__programs {
    object-fit: cover;

    &__container {
      z-index: 5;
      opacity: 0;
      max-width: 517px;
      max-height: 619px;
      margin-left: 165px;
      width: calc(100vh - 560px);
      animation: fadeIn 1s 4s 1 forwards;

      @media (max-width: $wide) {
        margin-left: 64px;
      }
    }
  }

  &__background {
    height: 100%;
    width: 100%;
    opacity: 0;
    position: fixed;
    background-repeat: no-repeat;

    &__container {
      position: absolute;
      height: 100vh;
      z-index: 0;
      width: 100%;
    }

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-name: fadeInLeft;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-duration: $i - 0.7s;
        z-index: $i;
      }
    }

    &_0 {
      background-image: url($backgroundExtraSmall);
    }
    &_1 {
      background-image: url($backgroundSmall);
    }
    &_2 {
      background-image: url($backgroundMid);
    }
    &_3 {
      background-image: url($backgroundBig);
      left: -722px;
      top: -222px;
      height: calc(100vh + 222px);
      width: calc(100% + 722px);

      @media (max-height: $desktop) {
        top: -400px;
        height: calc(100vh + 400px);
        left: -822px;
        width: calc(100% + 822px);
      }
    }
  }

  &__heading {
    position: relative;
    padding: 48px 0 0 80px;
    z-index: 5;
    max-width: 442px;
    opacity: 0;
    animation: fadeIn 1s 3s 1 forwards;

    &__divider {
      width: 100%;
      height: 2px;
      margin-bottom: 24px;
      background: radial-gradient(146930699.63% 50.37% at 50.37% 0%, $primary-10 0%, rgba(0, 149, 250, 0) 100%);
    }

    &__amatuni {
      gap: 0;
      margin-bottom: 24px;
    }

    &__title {
      color: $primary-800;
      @extend %heading-1;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $primary-1800;
      margin-bottom: 16px;
    }

    &__subtitle {
      color: $primary-1900;
      @extend %body1-lt;
    }
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeftForBig {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(-45%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
