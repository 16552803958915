@import 'styles/colors';
@import 'styles/typography';

.container {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &__situation {
    @extend %micro-sb;
    border: 1px solid;
    padding: 4px 12px;
    border-radius: 24px;
    display: inline-block;
    text-transform: uppercase;

    &_paid {
      color: $success-75;
      border-color: $success-75;
    }

    &_failed {
      color: $error-75;
      border-color: $error-75;
    }

    &_pending {
      color: $warning-75;
      border-color: $warning-75;
    }
  }
}
