@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;

  &__label {
    color: $secondary-1500;
    box-sizing: border-box;
    margin-bottom: 4px;
    @extend %body2-r;

    &__large {
      @extend %body1-r;
    }
  }

  &__icon {
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translateY(-50%);

    &__large {
      left: 16px;
      width: 20px;
      height: 20px;
    }
  }

  &__input {
    width: 100%;
    height: 32px;
    appearance: none;
    @include inputStyles();

    &:focus-within + .wrapper__box__container {
      background-color: $background-90;
    }

    &__icon {
      padding-left: 32px;
    }

    &__large {
      height: 48px;
      padding: 0 12px 0 16px;

      &__icon {
        padding-left: 44px;
      }
    }

    &__error {
      border-color: $error !important;
    }
  }

  &__box {
    position: relative;
    border-radius: 8px;
    background: $background-80;
    @extend %flex-center;

    &__container {
      gap: 4px;
      top: 50%;
      right: 8px;
      padding-left: 4px;
      position: absolute;
      transition: 0.6s all;
      transform: translateY(-50%);
      background-color: $background-80;
      @extend %flex-center;
    }

    &__tooltip {
      padding: 2px !important;
      @include svgSize(16px !important);

      &__large {
        padding: 6px !important;
        @include svgSize(20px !important);
      }
    }

    &__eye {
      @extend %flex-center;
      @include svgFill($secondary-1300, 20px);

      &__open {
        @include svgFill($primary-1900);
      }
    }
  }
}
