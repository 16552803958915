@import 'styles/colors.scss';

.wrapper {
  position: relative;
  width: 20px;
  height: 20px;

  div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    border: 2px solid white;
    border-color: white transparent transparent transparent;
    animation: wrapper 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes wrapper {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
